var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xl-4 col-lg-5 px-4 settings-content"},[_c('div',{staticClass:"px-0 mx-0 pb-3"},[_c('div',{staticClass:"row mx-0"},[_c('h4',{staticClass:"px-0 pt-4 pb-3 col-12"},[(_vm.isPromoter)?[_vm._v(" LINKS FOR YOUR VENUE/FESTIVAL/PROMOTIONS COMPANY ")]:[_vm._v(" Identity links for music matching and profile building ")]],2),_c('p',{staticClass:"px-0 col-12 online-preference-text"},[(_vm.isPromoter)?[_vm._v(" Please fill out as many of the following links to your brand."),_c('br'),_vm._v(" (minimum of 2 is required) ")]:[_vm._v(" In order to verify music across multiple public sites and databases and build your artist profile we ask you to please fill in as many links as possible to assist us in accurate matches and guarantee future payouts. ")]],2),(!_vm.isPromoter)?[_vm._m(0),_vm._l((_vm.identityLinks),function(link,index){return _c('div',{key:index,staticClass:"mt-3 px-0 col-12"},[_c('label',{staticClass:"mb-0 label-text"},[_vm._v(_vm._s(link.title))]),_c('div',{staticClass:"form-group mb-0 field-badge",class:{
              'field-badge_yellow':
                _vm.user.verified &&
                !!!_vm.user.socials.find(
                  function (socialLink) { return socialLink.link === link.link &&
                    !!link.link &&
                    link.type === socialLink.type; }
                ),
              'field-badge_green':
                _vm.user.verified &&
                !!_vm.user.socials.find(
                  function (socialLink) { return socialLink.link === link.link &&
                    !!link.link &&
                    link.type === socialLink.type; }
                )
            }},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.identityLinks.$each[index].link.$model),expression:"$v.identityLinks.$each[index].link.$model",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","disabled":_vm.user.verified &&
                  !!_vm.user.socials.find(
                    function (socialLink) { return socialLink.link === link.link &&
                      !!link.link &&
                      link.type === socialLink.type; }
                  )},domProps:{"value":(_vm.$v.identityLinks.$each[index].link.$model)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.identityLinks.$each[index].link, "$model", $event.target.value.trim())},_vm.handleInputChange],"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.$v.identityLinks.$each[index].link.$error)?_c('div',{staticClass:"error"},[_vm._v(" Please enter as url. ")]):_vm._e()])])})]:_vm._e(),_c('b-col',{staticClass:"mt-3 px-0",attrs:{"cols":"12","no-gutters":""}},[_c('h4',{staticClass:"mt-4 mb-3"},[_vm._v(" Social profile ")])]),_vm._l((_vm.visibleSocialProfileLinks),function(link,index){return _c('b-col',{key:_vm.identityLinks.length + index,staticClass:"mt-3 px-0 col-7",attrs:{"cols":"12","no-gutters":""}},[_c('label',{staticClass:"mb-0 label-text"},[_vm._v(_vm._s(link.title))]),_c('div',{staticClass:"form-group mb-0 field-badge",class:{
            'field-badge_yellow':
              _vm.user.verified &&
              !!!_vm.user.socials.find(
                function (socialLink) { return socialLink.link === link.link &&
                  !!link.link &&
                  link.type === socialLink.type; }
              ),
            'field-badge_green':
              _vm.user.verified &&
              !!_vm.user.socials.find(
                function (socialLink) { return socialLink.link === link.link &&
                  !!link.link &&
                  link.type === socialLink.type; }
              )
          }},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.socialProfileLinks.$each[index].link.$model),expression:"$v.socialProfileLinks.$each[index].link.$model",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Enter Link","disabled":_vm.user.verified &&
                !!_vm.user.socials.find(
                  function (socialLink) { return socialLink.link === link.link &&
                    !!link.link &&
                    link.type === socialLink.type; }
                )},domProps:{"value":(_vm.$v.socialProfileLinks.$each[index].link.$model)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.socialProfileLinks.$each[index].link, "$model", $event.target.value.trim())},_vm.handleInputChange],"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.$v.socialProfileLinks.$each[index].link.$error)?_c('div',{staticClass:"error"},[_vm._v(" Please enter as url. ")]):_vm._e()])])})],2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12 px-0"},[_c('h4',{staticStyle:{"margin-top":"20px","margin-bottom":"10px"}},[_vm._v(" Identity links ")])])}]

export { render, staticRenderFns }